import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import CardProject from '../components/CardProject';
import ContactsBlock from '../components/ContactsBlock';
import Layout from '../components/layout';
import DecorationBlob20 from '../images/decorations/deco-blob-20.svg';
import DecorationBlob21 from '../images/decorations/deco-blob-21.svg';
import DecorationBlob4 from '../images/decorations/deco-blob-4.svg';
import DecorationDots2 from '../images/decorations/deco-dots-2.svg';
import DecorationDots6 from '../images/decorations/deco-dots-6.svg';
import DecorationLine2 from '../images/decorations/deco-lines-2.svg';
import DecorationLine4 from '../images/decorations/deco-lines-4.svg';
import DecorationLine7 from '../images/decorations/deco-lines-7.svg';
import Divider1 from '../images/dividers/divider-1.svg';
import Divider6 from '../images/dividers/divider-6.svg';
import IconDesktop from '../images/icons/theme/devices/display-2.svg';
import IconPhone from '../images/icons/theme/devices/iphone-x.svg';
import IconResponsive from '../images/icons/theme/general/size.svg';

const UxUiDesignSevicesPage = ({ data, location: { pathname } }) => {
  return (
    <Layout
      meta={{
        seo: data.datoCmsPage.seo,
        path: pathname,
      }}
    >
      {/* <UxUiDesignSevicesJsonLd /> */}
      <section className="pt-5 o-hidden has-divider">
        <div className="container py-0">
          <div className="row align-items-center min-vh-40">
            <div className="col-lg-7 text-lg-left mb-4 mb-lg-0">
              <h1 className="display-4">UX UI Design Services</h1>
              <div className="my-5">
                <p className="h4 mb-4">
                  Here’s how we turn UX into your competitive advantage 
                </p>
                <ul className="list text-left">
                <li className="list-item list-item-primary">
                  UX Audits & Improvements
                </li>
                <li className="list-item list-item-primary">
                  Product Design from Scratch
                </li>
                <li className="list-item list-item-primary">
                  UX Research
                </li>
                <li className="list-item list-item-primary">
                  UX Consulting
                </li>
              </ul>
              </div>
              <Link
                to="/contact-us"
                id="freeconsultation"
                className="btn btn-lg btn-primary"
              >
                Contact Us
              </Link>
            </div>
            <div className="col-lg-5">
              <div className="special-width d-none d-md-block">
                <GatsbyImage
                  objectFit="contain"
                  alt="UI/UX Design Services"
                  style={{width: 800, height: 550}}
                  image={getImage(data.imageFirstSection)}
                  loading="eager"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='o-hidden'>
        {/* <div
          className="divider"
          style={{
            position: 'relative',
            top: 0,
            transform: 'scaleX(-1) scaleY(-1)',
            zIndex: 100,
          }}
        >
          <Divider1 />
        </div> */}
        <div className="container pt-4">
          <div className='row text-center'><h2 className="pb-6 display-4 m-auto">Use cases</h2></div>
          <div className="row align-items-center">
            {/* <DecorationLine2 className="d-none d-lg-inline decoration-lines-2 decoration-color-primary-2" /> */}
            <div className="col-sm pb-5 pb-md-0" id="mvp">
              <h3>Design from scratch</h3>
              <p className="lead pb-2">
                From day one, we’ll be helping you move towards building a
                product people want through user research, impeccable UX and
                striking visuals.
              </p>
              <ul className="list pb-3">
                <li className="list-item">
                  Start the project on the right foot
                </li>
                <li className="list-item">Avoid costly mistakes</li>
                <li className="list-item">
                  Focus on what matters from the beginning
                </li>
              </ul>
              <Link to="/ux-design-process" className="btn btn-lg btn-primary">
                Check our UX process
              </Link>
            </div>
            <div className="col-12 col-md-6 pl-3">
              <GatsbyImage
                alt="UX Design from Scratch"
                image={getImage(data.designScratch)}
                loading="eager"
                class="special-width"
              />
            </div>
            
          </div>
          {/* <DecorationLine4
            className="d-none d-lg-inline decoration-lines-4"
            style={{
              top: 'auto',
              bottom: '4rem',
              left: '30%',
            }}
          /> */}
        </div>
{/*         <div className="divider">
          <Divider1 />
        </div> */}
      </section>
      <section className="pb-0 pb-md-7 o-hidden">
        {/* <DecorationBlob20
          className="d-none d-lg-inline"
          style={{ position: 'absolute' }}
        />
        <DecorationDots6
          className="d-none d-lg-inline"
          width="300"
          height="300"
          style={{
            position: 'absolute',
            transform: 'rotate(350deg)',
            bottom: '-4rem',
            left: '11rem',
          }}
        /> */}

        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 pr-3 order-2 order-md-1">
              <GatsbyImage
                alt="Redesign & Iterative Improvement"
                image={getImage(data.redesignIterative)}
                loading="eager"
                class="special-width left-50"
              />
            </div>
            <div className="col-12 col-md-6 order-1" id="scale">
              <h3>Redesign & Iterative Improvement</h3>
              <p className="lead pb-2">
                Whether you need to revamp an existing solution or build new
                features on top, we’ll help you boost retention, conversion and
                engagement.
              </p>
              <ul className="list">
                <li className="list-item list-item-primary">
                  Breathe new life into dated UI & UX
                </li>
                <li className="list-item list-item-primary">
                  Focus on the easiest improvements that make the most impact
                </li>
                <li className="list-item list-item-primary">
                  Ease you way into a brand new design
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-0 pb-md-7 o-hidden">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6" id="audit">
              <h3>UX Audit</h3>
              <p className="lead pb-2">
                Struggling with underperforming metrics? – We can tell you
                exactly where the issues are, and most importantly – how to fix
                them.
              </p>
              <ul className="list">
                <li className="list-item">
                  Identify usability issues causing churn and revenue loss.
                </li>
                <li className="list-item">
                  Get an improvement roadmap that prioritizes easy and impactful
                  wins.
                </li>
                <li className="list-item">
                  Get recommendations on future designs to avoid issues in the
                  long-term.
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-6 pl-3">
              <GatsbyImage
                alt="UX Audit"
                image={getImage(data.uxAudit)}
                loading="eager"
                class="special-width"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="pb-0 pb-md-7 o-hidden">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 pr-3 order-2 order-md-1">
              <GatsbyImage
                objectFit="contain"
                alt="Consulting"
                image={getImage(data.consulting)}
                loading="eager"
                class="special-width left-50"
              />
            </div>
            <div className="col-12 col-md-6 order-1 pb-5 pb-md-0">
              <h3>Consulting</h3>
              <p className="lead pb-2">
                We can help you fulfill your business needs with your own hands
                and resources, while answering the most pressing questions you
                have.
              </p>
              <ul className="list pb-3">
                <li className="list-item list-item-primary">
                  Allocate your resources in the most efficient way
                </li>
                <li className="list-item list-item-primary">
                  Get a clear UX & Product roadmap
                </li>
                <li className="list-item list-item-primary">
                  Get a new perspective into your design process
                </li>
              </ul>
              <Link to="/ux-consulting" className="btn btn-lg btn-primary">
                Check our UX consulting
              </Link>
            </div>
          </div>
        </div>
        {/* <DecorationBlob21
          className="d-none d-lg-inline"
          style={{ position: 'absolute', right: 0, top: '10rem' }}
        /> */}
        <DecorationLine2
          className="d-none d-lg-inline decoration-lines-2 decoration-color-primary-2"
          style={{
            height: '70px',
            top: '30rem',
            right: '7.5rem',
            transform: 'rotate(340deg)',
          }}
        />
      </section>
      <section className='o-hidden'>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <h3>Design System as a Service</h3>
              <p className="lead pb-2">
                Let us help you scale your design and ultimately businesses
                through meticulous organization of your interface components.
              </p>
              <ul className="list">
                <li className="list-item">
                  Accelerate your design & development efforts
                </li>
                <li className="list-item">
                  Ensure design consistency & efficiency
                </li>
                <li className="list-item">
                  Move your old design system to Figma
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-6 pl-3">
              <GatsbyImage
                objectFit="contain"
                alt="Design System as a Service"
                image={getImage(data.designSystemService)}
                loading="eager"
                class="special-width"
              />
            </div>
          </div>
        </div>
      </section>

      {/* 
      DESIGN PROCESS CTA BLOCK

      <section className="py-2 o-hidden">
        <div
          className="container bg-primary-alt py-5 px-6"
          style={{ maxWidth: '78rem' }}
        >
          <h3>Check our design process</h3>
          <p className="lead pb-3">
            Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet
          </p>
          <Link
            to="/contact-us"
            className="btn btn-lg btn-primary"
          >
            Learn more
          </Link>
          <DecorationLine7
            className="d-none d-lg-inline"
            style={{
              position: 'absolute',
              right: '-5rem',
              top: '3rem',
            }}
          />
        </div>
      </section> */}

      <section className="pb-0 pb-md-3 o-hidden">
        <div className="container">
          <div className='row text-center'><h2 className="pb-6 display-4 m-auto">Activities</h2></div>
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <h3>UX Design</h3>
              <p className="lead pb-2">
                Empower your product through putting users first. The rewards
                you’ll reap are outstanding metrics, word-of-mouth and
                scalability.
              </p>
              <ul className="list">
                <li className="list-item">
                  <span className="font-weight-bolder">
                    Information Architecture
                  </span>
                  <br />
                  Make sure users can seamlessly navigate through your product
                </li>
                <li className="list-item">
                  <span className="font-weight-bolder">Wireframing</span>
                  <br />
                  Nail the structure before committing costly resources
                </li>
                <li className="list-item">
                  <span className="font-weight-bolder">User Flows</span>
                  <br />
                  Help users progress through your product friction-free
                </li>
                <li className="list-item">
                  <span className="font-weight-bolder">
                    Customer Journey Mapping
                  </span>
                  <br />
                  Build a cohesive path from a stranger to a life-long
                  evangelist
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-6 pl-3">
              <GatsbyImage
                objectFit="contain"
                alt="UX Design"
                image={getImage(data.uxDesign)}
                loading="eager"
                class="special-width"
              />
            </div>
          </div>
        </div>
        <div
          className="d-none d-lg-flex justify-content-between align-items-center decoration-container"
          style={{ top: 'auto', bottom: '0' }}
        >
          {/* <div className="decoration-wrapper">
            <div className="decoration">
              <DecorationDots2 className="bg-primary decoration-dots" />
            </div>

            <div className="decoration">
              <DecorationBlob4 className="bg-primary-2 decoration-blob-4" />
            </div>
          </div> */}
        </div>
      </section>
      <section className="pb-0 pb-md-7 o-hidden">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 pr-3 order-2 order-md-1">
              <GatsbyImage
                objectFit="contain"
                alt="UX Research"
                image={getImage(data.uxResearch)}
                loading="eager"
                class="special-width left-50"
              />
            </div>
            <div className="col-12 col-md-6 order-1" id="research">
              <h3>UX Research</h3>
              <p className="lead pb-2">
                Get answers to the most pressing strategic business questions
                through proper UX research practices.
              </p>
              <ul className="list">
                <li className="list-item list-item-primary">
                  <span className="font-weight-bolder">Persona creation</span>
                  <br />
                  Hyperpersonalize your product
                </li>
                <li className="list-item list-item-primary">
                  <span className="font-weight-bolder">
                    Competitive Analysis
                  </span>
                  <br />
                  Get ahead of the competition
                </li>
                <li className="list-item list-item-primary">
                  <span className="font-weight-bolder">User Interviews</span>
                  <br />
                  Validate your product ideas
                </li>
                <li className="list-item list-item-primary">
                  <span className="font-weight-bolder">Usability Testing</span>
                  <br />
                  Learn exactly how to make your product better
                </li>
                <li className="list-item list-item-primary">
                  <span className="font-weight-bolder">
                    Stakeholder interviews
                  </span>
                  <br />
                  Get the whole team to working toward the same goal
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container o-hidden">
          <div className="row align-items-center">
            <div className="col-sm">
              <h3>Visual design & Documentation</h3>
              <p className="lead pb-2">
                Striking visuals are no longer just “nice-to-have”.
                State-of-the-art user interfaces earns you the initial credit of
                trust.
              </p>
              <ul className="list">
                <li className="list-item">
                  <span className="font-weight-bolder">
                    User Interface Design
                  </span>
                  <br />
                  Blow users away with stunning visuals
                </li>
                <li className="list-item">
                  <span className="font-weight-bolder">Design System</span>
                  <br />
                  Scale your design and ensure maximum efficiency
                </li>
                <li className="list-item">
                  <span className="font-weight-bolder">Style Guide</span>
                  <br />
                  Ensure consistent brand identity
                </li>
                <li className="list-item">
                  <span className="font-weight-bolder">Quality Assurance</span>
                  <br />
                  Eliminate any chance of design blunders ever going live
                </li>
              </ul>
            </div>
            <div className="col-sm pl-3">
              <GatsbyImage
                objectFit="contain"
                alt="Visual design & Documentation"
                image={getImage(data.visualDesign)}
                loading="eager"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-primary-alt pb-5">
        <div className="container text-center">
          <h2 className="display-4">Platforms</h2>
          <p className="pb-4 lead">
            Having started in 2016, we’ve designed for all platforms under the
            sun: desktop, web, mobile,
            <br />
            responsive, wearables, you name it.
          </p>
          <div className="row pb-5">
            <div className="col-sm pb-4">
              <div
                className="icon-round mx-auto mb-2"
                style={{
                  width: '3.5rem',
                  height: '3.5rem',
                  background: 'white',
                }}
              >
                <IconPhone />
              </div>

              <p className="h5" style={{ fontWeight: 700 }}>
                Mobile, wearable,
                <br />
                tablet designs
              </p>
            </div>
            <div className="col-sm pb-4">
              <div
                className="icon-round mx-auto mb-2"
                style={{
                  width: '3.5rem',
                  height: '3.5rem',
                  background: 'white',
                }}
              >
                <IconDesktop />
              </div>
              <p className="h5" style={{ fontWeight: 700 }}>
                Desktop and web
                <br />
                applications
              </p>
            </div>
            <div className="col-sm">
              <div
                className="icon-round mx-auto mb-2"
                style={{
                  width: '3.5rem',
                  height: '3.5rem',
                  background: 'white',
                }}
              >
                <IconResponsive />
              </div>
              <p className="h5" style={{ fontWeight: 700 }}>
                Responsive
                <br />
                apps
              </p>
            </div>
          </div>
          <div className="pl-md-3">
            <GatsbyImage
              objectFit="contain"
              alt="Platforms"
              image={getImage(data.platforms)}
              loading="eager"
            />
          </div>
        </div>
      </section>
      <section className="bg-primary has-divider d-none d-lg-block">
        <div className="container pb-6 pb-md-0">
          <div className="row align-items-center">
            <div className="col-sm">
              <div style={{ maxWidth: '350px' }}>
                <CardProject
                  cardData={data.allDatoCmsProject.edges[0].node}
                  type="vertical"
                />
              </div>
            </div>
            <div className="col-sm">
              <h2 className="text-white pt-3">See our case studies</h2>
              <p className="lead pb-2 text-white">
                UX isn’t just the icing on top, it’s about helping you reach
                your tangible business goals. Check our case studies to see for
                yourself.
              </p>
              <ul className="lead text-white">
                <li className="pb-3">Start the project on the right foot</li>
                <li className="pb-3">Avoid costly mistakes</li>
                <li className="pb-3">
                  Focus on what matters from the beginning
                </li>
              </ul>
              <Link to="/ux-projects" className="btn btn-lg btn-white">
                See all case studies
              </Link>
            </div>
          </div>
        </div>
        <div className="divider bg-primary-3">
          <Divider6
            className="bg-primary"
            style={{ position: 'relative', top: '-1px' }}
          />
        </div>
      </section>

      <ContactsBlock isInViewProp={true} />
    </Layout>
  );
};

export default UxUiDesignSevicesPage;

export const query = graphql`
  query UxUiDesignSevicesQuery {
    allDatoCmsProject(
      limit: 1
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          cardImage {
            gatsbyImageData(width: 1600, layout: CONSTRAINED)
          }
          logo {
            url
          }
          cardColor {
            hex
          }
          cardDescription
          categoryProject
          industry
          slug
          id
          secretProject
        }
      }
    }
    imageFirstSection: file(
      relativePath: { eq: "photos/ui-ux-design-services.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 579)
      }
    }
    redesignIterative: file(
      relativePath: { eq: "photos/redesign-iterative.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    designScratch: file(relativePath: { eq: "photos/ux-design-from-scratch.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    uxAudit: file(relativePath: { eq: "photos/ux-audit1.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    consulting: file(relativePath: { eq: "photos/consulting.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    designSystemService: file(
      relativePath: { eq: "photos/design-system-service.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    uxDesign: file(relativePath: { eq: "photos/activities-ux-design.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    uxResearch: file(relativePath: { eq: "photos/ux-research.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    visualDesign: file(relativePath: { eq: "photos/visual-design.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    platforms: file(relativePath: { eq: "photos/platforms.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    datoCmsPage(slug: {eq: "ux-ui-design-services"}) {
      seo {
          title
          description
          twitterCard
          image {
              url
          }
      }
  }
  }
`;
