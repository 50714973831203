import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const CalendlyWidget = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;

    // Append the script to the body
    document.body.appendChild(script);

    // Clean up function to remove the script from the body on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <Helmet>
        <style>{` .calendly-inline-widget { min-width: 320px; height: 700px; } `}</style>
      </Helmet>
      <div className="calendly-inline-widget" data-url="https://calendly.com/adamfard/quick-chat-w?hide_event_type_details=1&branding=0&hide_gdpr_banner=1&primary_color=2d63d7"></div>
    </div>
  );
};

export default CalendlyWidget;